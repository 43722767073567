import {Component, ViewChild} from '@angular/core';
import {NgClass} from "@angular/common";
import {DropdownComponent} from "@revolve-app/app/shared/dropdown/dropdown.component";
import {TranslateService} from "@ngx-translate/core";
import {StorageKeys, StorageService} from "@revolve-app/app/core/utils/storage.service";

export interface ILanguage{
  name: string,
  code: string,
  logo: string
}

@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  standalone: true,
  imports: [
    NgClass,
    DropdownComponent
  ],
  providers: [TranslateService],
  styleUrls: ['./language-dropdown.component.scss']
})
export class LanguageDropdownComponent{
  @ViewChild('langDropdown') langDropdown: any;
  currentLanguage: ILanguage = {
    name: 'HY',
    code: 'hy',
    logo: '/assets/images/languages/hy.png'
  };
  supportedLanguages: ILanguage[] = [
    {
      name: 'RU',
      code: 'ru',
      logo: '/assets/images/languages/ru.png'
    },
    {
      name: 'HY',
      code: 'hy',
      logo: '/assets/images/languages/hy.png'
    },
  ];
  constructor(private translateService: TranslateService) {
    let currentLanguage = StorageService.getItem(StorageKeys.LANGUAGE);
    if(!currentLanguage) {
      currentLanguage = 'hy';
      this.translateService.use(currentLanguage);
    }

    let currentLanguageObj = this.supportedLanguages.find((item) => item.code === currentLanguage);
    if(!currentLanguageObj) {
      currentLanguageObj = this.currentLanguage;
    }
    this.currentLanguage = currentLanguageObj;
  }

  selectLanguage(language: ILanguage){
    this.currentLanguage = language;
    this.langDropdown.toggleMenu();
    this.translateService.use(language.code);
    StorageService.setItem(StorageKeys.LANGUAGE, language.code)
  }

}
