import {
  Component, EventEmitter, Inject, Input,
  OnInit, Output, PLATFORM_ID,
} from '@angular/core';
import {isPlatformBrowser, NgClass} from "@angular/common";
import {DropdownComponent} from "@revolve-app/app/shared/dropdown/dropdown.component";
import {RouterLink} from "@angular/router";
import {AuthModule} from "@revolve-app/app/core/features/auth/store/auth/auth.module";
import {Store} from "@ngrx/store";
import {signOut} from "@revolve-app/app/core/features/auth/store/auth/auth.actions";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  standalone: true,
  imports: [
    NgClass,
    DropdownComponent,
    AuthModule,
    RouterLink,
    TranslateModule
  ],
  styleUrls: ['./profile-dropdown.component.scss']
})
export class ProfileDropdownComponent implements OnInit {
  navigation: any;
  public displayName: any;
  @Input() show = false;
  @Input() account = {};
  @Output() toggleModal: EventEmitter<any> = new EventEmitter<any>();

  constructor(private store: Store, @Inject(PLATFORM_ID) private platformId: object) {
  }


  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.displayName = ''//this.account?.displayName || '';
    }
  }

  logout(){
    this.store.dispatch(signOut())
  }

  toggleModalEmitter(): void {
    this.show = !this.show;
    this.toggleModal.emit(this.show);
  }



}
