<app-dropdown #langDropdown>
  <div class="dropdown-button grid grid-cols-3 gap-1 py-2 ">
    <img class="w-6 h-6 me-2 rounded-full" [src]="currentLanguage.logo" [alt]="currentLanguage.code">
    <div>{{currentLanguage.name}}</div>
    <div class="leading-none">⌄</div>
  </div>
  <div class="dropdown-options">
    <ul class="max-h-48 py-2 overflow-y-auto text-gray-700 dark:text-gray-200" aria-labelledby="dropdownUsersButton">
      @for(language of supportedLanguages; let i= $index; track language.code) {
        <li>
          <a (click)="selectLanguage(language)" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
            <img class="w-6 h-6 me-2 rounded-full" [src]="language.logo" [alt]="language.code">
            {{language.name}}
          </a>
        </li>
      }
    </ul>
  </div>
</app-dropdown>
