import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { StorageKeys, StorageService } from '@revolve-app/app/core/utils/storage.service';

@Directive({
    standalone: true,
    selector: '[isAuth]'
})
export class AuthDirective {
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {}

    @Input() set isAuth(forAuth: boolean) {
      if(forAuth){
        if (StorageService?.getItem(StorageKeys.TOKEN)) {  // Replace this with your actual auth service function to check roles
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      } else {
        if (StorageService?.getItem(StorageKeys.TOKEN)) {  // Replace this with your actual auth service function to check roles
          this.viewContainer.clear();
        } else {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      }

    }

}
