import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router, RouterLink, RouterLinkActive} from '@angular/router';
import { PermissionBasedDirective } from '@revolve-app/app/core/utils/permission-based.directive';
import {
  LanguageDropdownComponent
} from "@revolve-app/app/theme/components/dropdowns/language-dropdown/language-dropdown.component";
import {
  ProfileDropdownComponent
} from "@revolve-app/app/theme/components/dropdowns/profile-dropdown/profile-dropdown.component";
import {SideBarComponent} from "@revolve-app/app/shared/side-bar/side-bar.component";
import {
  ButtonComponent,
  TButtonsSizes,
  TButtonsTypes
} from "@revolve-app/app/shared/forms/buttons/button/button.component";
import {NgClass} from "@angular/common";
import {AuthDirective} from "@revolve-app/app/core/utils/auth.directive";
import {signOut} from "@revolve-app/app/core/features/auth/store/auth/auth.actions";
import {Store} from "@ngrx/store";
import {TranslateModule} from "@ngx-translate/core";
import {StorageKeys, StorageService} from "@revolve-app/app/core/utils/storage.service";
import {updateUserSession} from "@revolve-app/app/core/features/accounts/store/me/me.actions";
@Component({
  selector: 'app-guest-header',
  standalone: true,
  templateUrl: './guest-header.component.html',
  imports: [
    RouterLink,
    PermissionBasedDirective,
    LanguageDropdownComponent,
    ProfileDropdownComponent,
    SideBarComponent,
    ButtonComponent,
    AuthDirective,
    RouterLinkActive,
    NgClass,
    PermissionBasedDirective,
    TranslateModule,
  ],
  styleUrl: './guest-header.component.scss',
})
export class GuestHeaderComponent implements OnInit{

  @Input() transparent: boolean = false;
  @Input() account: any = null;
  @ViewChild('mobileSidebar') mobileMenu: any;
  constructor(private store: Store,
              private routerService: Router) {
  }

  ngOnInit(): void {
  }

  toggleMenu(){
    this.mobileMenu.toggleModalEmitter();
  }

  logout(){
    this.store.dispatch(signOut())
  }

  protected readonly TButtonsSizes = TButtonsSizes;
  protected readonly TButtonsTypes = TButtonsTypes;
}
