<div #currentScope class="relative z-50 lg:hidden transition-opacity ease-linear duration-300"
     [ngClass]="{
      'opacity-0': !show,
      'opacity-100': show
      }" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-900/80" [ngClass]="{hidden:!show}" ></div>

  <div class="fixed inset-0 flex transition ease-in-out duration-300 transform" [ngClass]="{
      '-translate-x-full': !show,
      'translate-x-0': show
      }">
    <div class="relative mr-16 flex w-full max-w-xs flex-1 ease-in-out duration-300"
         [ngClass]="{
      'opacity-0': !show,
      'opacity-100': show
      }">
      <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
        <button type="button" (click)="toggleModalEmitter()" class="-m-2.5 p-2.5">
          <span class="sr-only">Close sidebar</span>
          <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-dark px-6 pb-4">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
