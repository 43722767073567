<header [ngClass]="{
'bg-white text-black': !transparent,
'bg-transparent text-white': transparent
}">
  @if(transparent){
    <nav class="container mx-auto flex items-center justify-between p-12 lg:px-20" aria-label="Global">
      <div class="flex items-center gap-x-12">
        <a href="#" class="-m-1.5 p-1.5">
          <span class="sr-only">KAEX</span>
          <img class="h-8 w-auto" src="/assets/images/logo/white_and_purple.png" alt="kaex logo">
        </a>
        <div class="hidden lg:flex lg:gap-x-12">
          <a [routerLink]="['/']"  class="text-20 font-semibold text-white leading-6">{{'home'| translate}}</a>
          <a [routerLink]="['/rates']"  class="text-20 font-semibold leading-6 text-white">{{'rate'| translate}}</a>
          <!--        <a [routerLink]="['/']" class="text-20 font-semibold leading-6 text-gray-900">Blog</a>-->
        </div>
      </div>
      <div class="flex lg:hidden">
        <button (click)="toggleMenu()" type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
          <span class="sr-only" i18n>Open main menu</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </button>
      </div>
      <div class="hidden lg:flex">
        <app-language-dropdown></app-language-dropdown>
        <app-profile-dropdown *isAuth="true" [account] = [account]></app-profile-dropdown>
        <app-button *isAuth="false" class="ml-2"
                    [text]="'sign_up' | translate"
                    [size]="TButtonsSizes.SMALL_REGULAR"
                    [type]="TButtonsTypes.PRIMARY"
                    [route]="['/auth/signup']"
        ></app-button>
        <app-button *isAuth="false" class="ml-2" [text]="'sign_in'| translate"
                    [route]="['/auth/signin']"
                    [size]="TButtonsSizes.SMALL_REGULAR"
                    [type]="TButtonsTypes.SECONDARY"></app-button>
      </div>
    </nav>
  } @else {
    <nav class="container mx-auto flex items-center justify-between p-12 lg:px-20" aria-label="Global">
      <div class="flex items-center gap-x-12">
        <a href="#" class="-m-1.5 p-1.5">
          <span class="sr-only">KAEX</span>
          <img class="h-8 w-auto" src="/assets/images/logo/black_and_purple.png" alt="kaex logo">
        </a>
        <div class="hidden lg:flex lg:gap-x-12">
          <a [routerLink]="['/']" [routerLinkActive]="'text-primary'" class="text-20 font-semibold leading-6 text-gray-900">{{'home'| translate}}</a>
          <a [routerLink]="['/rates']" [routerLinkActive]="'text-primary'" class="text-20 font-semibold leading-6 text-gray-900">{{'rate'| translate}}</a>
          <!--        <a [routerLink]="['/']" class="text-20 font-semibold leading-6 text-gray-900">Blog</a>-->
        </div>
      </div>
      <div class="flex lg:hidden">
        <button (click)="toggleMenu()" type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
          <span class="sr-only" i18n>Open main menu</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </button>
      </div>
      <div class="hidden lg:flex">
        <app-language-dropdown></app-language-dropdown>
        <app-profile-dropdown *isAuth="true"></app-profile-dropdown>
        <app-button *isAuth="false" class="ml-2"
                    [text]="'sign_up'| translate"
                    [size]="TButtonsSizes.SMALL_REGULAR"
                    [type]="TButtonsTypes.PRIMARY"
                    [route]="['/auth/signup']"
        ></app-button>
        <app-button *isAuth="false" class="ml-2"
                    [text]="'sign_in'| translate"
                    [route]="['/auth/signin']"
                    [size]="TButtonsSizes.SMALL_REGULAR"
                    [type]="TButtonsTypes.SECONDARY"></app-button>
      </div>
    </nav>
  }

  <app-side-bar #mobileSidebar>
    <a href="#" class="-m-1.5 p-1.5 mt-2">
      <span class="sr-only">Kaex</span>
      <img class="h-8 w-auto" src="/src/assets/images/logo/black_and_purple.png" alt="">
    </a>
    <div class="-my-6 divide-y divide-gray-500/10">
      <div class="space-y-2 py-6">
        <a [routerLink]="['/']" (click)="toggleMenu()"  [routerLinkActive]="'text-primary'" class="-mx-3 block tex rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50" i18n>Home</a>
        <a [routerLink]="['/rates']" (click)="toggleMenu()"  [routerLinkActive]="'text-primary'" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50" i18n>Rate</a>
<!--        <a [routerLink]="['/']" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Blog</a>-->
      </div>
      <div class="py-6"  *isAuth="false">
        <a [routerLink]="['/auth/signin']" (click)="toggleMenu()"  class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-50" i18n>Log in</a>
      </div>
      <div class="py-6"  *isAuth="true">
        <a (click)="logout()"  class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-50" i18n>Logout</a>
      </div>
    </div>
  </app-side-bar>

</header>
