<app-dropdown #langDropdown>
  <div class="dropdown-button flex bg-dark px-2 py-2 rounded items-center">
    <div class="px-2 py-1 mx-2 rounded-full bg-gray-500 text-white">
      <i class="icon icon-profile"></i>
    </div>

    <div class="text-white">{{ displayName || 'anonymous' }}</div>
  </div>
  <div class="dropdown-options">
    <ul class="max-h-96 py-2 overflow-y-auto text-gray-700 dark:text-gray-200" aria-labelledby="dropdownUsersButton">
        <li>
          <a [routerLink]="['/customer/dashboard']" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
            <i class="icon-wallet"></i>
            <span class="ml-2">{{'Wallet'| translate}}</span>
          </a>
          <a [routerLink]="['/customer/transactions']" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
            <i class="icon-transactions"></i>
            <span class="ml-2">{{ 'Transactions'| translate }}</span>
          </a>
          <a [routerLink]="['/customer/orders']" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
            <i class="icon-change"></i>
            <span class="ml-2">{{ 'Orders'| translate }}</span>
          </a>
          <a [routerLink]="['/customer/me']" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
            <i class="icon-profile"></i>
            <span class="ml-2">{{ "Profile" | translate }}</span>
          </a>
          <a (click)="logout()" class="flex items-center mt-1 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
            <i class="icon-logout"></i>
            <span class="ml-2">{{ "Logout" | translate }}</span>
          </a>
        </li>
    </ul>
  </div>
</app-dropdown>
