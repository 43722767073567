import {
  Component, ElementRef, EventEmitter, HostListener, Input, OnChanges,
  OnInit, Output,
  SimpleChanges, ViewChild,
} from '@angular/core';
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  standalone: true,
  imports: [
    NgClass
  ],
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

  @ViewChild('currentScope') currentScope?: ElementRef;
  @Output('toggleModal') toggleModal: EventEmitter<any> = new EventEmitter<any>();
  @Input() show = false;

  constructor() {
  }


  ngOnInit(): void {
  }

  toggleModalEmitter(): void {
    setTimeout(()=>{
      this.show = !this.show;
    })
  }

  public  closeHostClick(){
    this.show = false;
  }


}
